$('.mini_carousel__slider').slick({
  slidesToShow: 6,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 381,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
});

$('.hero').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  infinite: true,
  centerMode: true,
  centerPadding: 'calc((100% - 1210px) / 2)',
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1301,
      settings: {
        centerPadding: '0',
        dots: true,
      },
    },
    {
      breakpoint: 425,
      settings: {
        dots: true,
        variableWidth: false,
      },
    },
  ]
});

$('.courses_sections_list__slider').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  focusOnSelect: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 701,
      settings: {
        dots: true,
        slidesToShow: 1,
      },
    },
  ],
});


$(document).ready(function () {
  $('.slider--thumbs').each(function () {
    $(this).slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      focusOnSelect: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 701,
          settings: {
            dots: true,
            slidesToShow: 1,
          },
        },
      ],
      asNavFor: $(this).parent().find('.slider'),
    });
  });

  $('.slider').each(function () {
    $(this).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      fade: true,
      infinite: true,
      adaptiveHeight: true,
      asNavFor: $(this).parent().find('.slider--thumbs'),
    });
  });

  if ($(window).width() < 1301) {
    let dots_w = $('.slick-dots').width();

    let slick_prev = $('.slick-dots').parent().find('.slick-prev');
    let slick_next = $('.slick-dots').parent().find('.slick-next');

    slick_prev.css({ 'left': 'calc(50% - ' + dots_w + 'px - 15px)' });
    slick_next.css({ 'right': 'calc(50% - ' + dots_w + 'px - 15px)' });
  }

  $('.js-slider-thumbs-card').on('click', (e) => {
    e.preventDefault();
    const data = $(e.currentTarget).closest('[data-slider-thumbs]').data('sliderThumbs');
    const sliderInfo = $(`[data-slider-info=${ data }]`);
    const index = $(e.currentTarget).data('slickIndex');

    if (sliderInfo.length > 0) {
      if (!sliderInfo.hasClass('active') && sliderInfo.data('index') !== index) {
        sliderInfo.slick('unslick').slick({
          dots: false,
          arrows: false,
          fade: true,
          initialSlide: index
        })
        sliderInfo.slideDown(300).addClass('active')
        sliderInfo.data('index', index)
      } else if (sliderInfo.hasClass('active') && sliderInfo.data('index') !== index) {
        sliderInfo.data('index', index)
      } else {
        sliderInfo.slideUp(300).removeClass('active')
        sliderInfo.data('index', null)
      }
    }
  })

  $('[data-slider-info]').slideUp(300)
});

